import React from 'react';

import SEO from '../components/Layout/SEO';
import Layout from '../components/Layout/Layout';
import SectionLight from '../components/shared/SectionLight';

const ErrorPage = ({ location }) => {
	return (
		<Layout location={location}>
			<SEO title='404' />
			<SectionLight>
				<h5>Oops! An error occurred, please try your request again.</h5>
			</SectionLight>
		</Layout>
	);
};

export default ErrorPage;
