import React from 'react';
import styled from '@emotion/styled';

import respond from '../../styles/mediaQueries';

const StyledSection = styled('section')`
    background-color: var(--bgPrimary);
	color: var(--text);
	padding: 0 6.4rem;

	${respond('bp2')} {
		padding: 0 4rem;
	}

	${respond('bp3')} {
		padding: 0 2.4rem;
	}

	${respond('bp4')} {
		padding: 0 1.2rem;
	}


	& p,
	& h5 {
        color: var(--text2)
	}
	
	& a {
		color: var(--text2);
		font-family: var(--fontHeader);
	}
`;

const SectionLight = ({ children }) => {
	return <StyledSection>{children}</StyledSection>;
};

export default SectionLight;
